<template>
  <div class="board">
    <div class="board-head">
      <div class="board-head-title">天道酬勤，力耕不欺
        <!-- 距离您完成目标还剩 100 天，您已经完成 80% 目标，超赞！继续加油哦！！！ -->
      </div>
      <div class="board-head-query">
        <Select v-model="teamId" style="width:200px" v-if="teamUsers" @on-change="dataRequest">
          <Option v-for="tu in teamUsers" :value="tu.id" :key="tu.id">{{tu.title}}</Option>
        </Select>
        <Select v-model="cycle" style="width:100px;margin-left:10px" @on-change="dataRequest">
          <Option v-for="cy in cycles" :value="cy.year" :key="cy.year">{{cy.year}}</Option>
        </Select>
      </div>
    </div>
    <div class="board-body">
      <div class="board-body-total">
        <div class="total-box">
          <div class="total-box-title">
            <div class="title-text">目标</div>
            <div class="title-link" @click="getUserGoal()" v-if="teamUser.type==2" title="设置目标"></div>
          </div>
          <div class="total-box-number">{{goalModel.total.totalGoal}}</div>
          <div class="total-box-process">
            <div class="process-text">
              <div class="process-text-rate">完成{{goalModel.total.totalCompleteRate}}%</div>
              <div class="process-text-value">{{goalModel.total.totalComplete}}</div>
            </div>
            <div class="process-chart">
              <div class="process-chart-value" :style="'width:'+(goalModel.total.totalCompleteRate>100?100:goalModel.total.totalCompleteRate)+'%'"></div>
            </div>
          </div>
        </div>
        <div class="total-box">
          <div class="total-box-title">
            <div class="title-text">应收</div>
          </div>
          <div class="total-box-number">{{goalModel.total.totalReceive}}</div>
          <div class="total-box-process">
            <div class="process-text">
              <div class="process-text-rate">完成{{goalModel.total.totalReceiveRate}}%</div>
              <div class="process-text-value">{{goalModel.total.totalComplete}}</div>
            </div>
            <div class="process-chart" style="background:#D8F6D8">
              <div class="process-chart-value" :style="'background:#64D964;width:'+goalModel.total.totalReceiveRate+'%'"></div>
            </div>
          </div>
        </div>
        <div class="total-box">
          <div class="total-box-title">
            <div class="title-text">商机</div>
          </div>
          <div class="total-box-number">{{goalModel.total.totalChance}}</div>
          <div class="total-box-image"></div>
        </div>
        <div class="total-box"></div>
        <div class="total-box">
          <div class="total-box-title">
            <div class="title-text">拜访</div>
          </div>
          <div class="total-box-number">{{goalModel.total.totalVisit}}</div>
          <div class="total-box-image img-visit"></div>
        </div>
      </div>
      <div class="board-body-rank">
        <div class="rank-box">
          <div class="rank-box-title">销售人员排名</div>
          <div class="rank-box-table">
            <div class="table-head">
              <div class="col w-40">排名</div>
              <div class="col w-full">姓名</div>
              <div class="col w-100">业绩</div>
            </div>
            <div class="table-body">
              <div class="table-row" v-for="(item, index) in goalModel.rankUser" :key="index">
                <div class="col w-40 icon-rank" v-if="index>2"><span>{{item.index}}</span></div>
                <div :class="'col w-40'+' icon-rank'+item.index" v-else></div>
                <div class="col w-full">{{item.name}}</div>
                <div class="col w-100 text-right">{{item.total}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="rank-box">
          <div class="rank-box-title">
            <div class="rank-box-text">排名</div>
            <div class="rank-box-radio">
              <RadioGroup v-model="rankType" type="button" size="small">
                <Radio label="区域"></Radio>
                <Radio label="客户"></Radio>
              </RadioGroup>
            </div>
          </div>
          <div class="rank-box-table" v-if="rankType=='区域'">
            <div class="table-head">
              <div class="col w-40">排名</div>
              <div class="col w-full">区域</div>
              <div class="col w-100 text-right">业绩</div>
              <div class="col w-scroll" v-if="goalModel.rankArea && goalModel.rankArea.length>8"></div>
            </div>
            <div class="table-body">
              <div class="table-row" v-for="(item, index) in goalModel.rankArea" :key="index">
                <div class="col w-40 icon-rank" v-if="index>2"><span>{{item.index}}</span></div>
                <div :class="'col w-40'+' icon-rank'+item.index" v-else></div>
                <div class="col w-full">{{item.name}}</div>
                <div class="col w-100 text-right">{{item.total}}</div>
              </div>
            </div>
          </div>
          <div class="rank-box-table" v-else>
            <div class="table-head">
              <div class="col w-40">排名</div>
              <div class="col w-full">客户</div>
              <div class="col w-100 text-right">业绩</div>
              <div class="col w-scroll" v-if="goalModel.rankCustomer && goalModel.rankCustomer.length>8"></div>
            </div>
            <div class="table-body">
              <div class="table-row" v-for="(item, index) in goalModel.rankCustomer" :key="index">
                <div class="col w-40 icon-rank" v-if="index>2"><span>{{item.index}}</span></div>
                <div :class="'col w-40'+' icon-rank'+item.index" v-else></div>
                <div class="col w-full">{{item.name}}</div>
                <div class="col w-100 text-right">{{item.total}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="table-box w60">
          <div class="table-box-title">项目列表</div>
          <div class="rank-box-table">
            <div class="table-head">
              <div class="col w-full text-left">项目名称</div>
              <div class="col w-100">业务代表</div>
              <div class="col w-100">生效日期</div>
              <div class="col w-100">业务归属</div>
              <div class="col w-100 text-right">项目金额</div>
              <div class="col w-100 text-right">回款金额</div>
              <div class="col w-100 text-right">应收金额</div>
              <div class="col w-scroll" v-if="goalModel.listProject && goalModel.listProject.length>8"></div>
            </div>
            <div class="table-body">
              <div class="table-row" v-for="(item, index) in goalModel.listProject" :key="index">
                <div class="col w-full text-left">{{item.name}}</div>
                <div class="col w-100">{{item.user}}</div>
                <div class="col w-100">{{item.time}}</div>
                <div class="col w-100">{{item.owner}}</div>
                <div class="col w-100 text-right">{{item.total}}</div>
                <div class="col w-100 text-right">{{item.close}}</div>
                <div class="col w-100 text-right">{{item.onpay}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="board-body-rank">
        <div class="rank-box">
          <div class="rank-box-title">
            <div class="rank-box-text">产品占比（元）</div>
          </div>
          <div class="chart-body" id="chart_product"></div>
        </div>
        <div class="rank-box">
          <div class="rank-box-title">拜访次数（次）</div>
          <div class="chart-body" id="chart_visit"></div>
        </div>
        <div class="table-box w60">
          <div class="table-box-title">商机列表</div>
          <div class="rank-box-table">
            <div class="table-head">
              <div class="col w-full text-left">项目名称</div>
              <div class="col w-100">业务代表</div>
              <div class="col w-100">生效日期</div>
              <div class="col w-100">业务归属</div>
              <div class="col w-100 text-right">商机金额</div>
              <div class="col w-scroll" v-if="goalModel.listChance && goalModel.listChance.length>8"></div>
            </div>
            <div class="table-body">
              <div class="table-row" v-for="(item, index) in goalModel.listChance" :key="index">
                <div class="col w-full text-left">{{item.name}}</div>
                <div class="col w-100">{{item.user}}</div>
                <div class="col w-100">{{item.time}}</div>
                <div class="col w-100">{{item.owner}}</div>
                <div class="col w-100 text-right">{{item.total}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="board-body-work">
        <div class="work-title">
          <div class="work-title-text">工作计划/客户拜访</div>
          <!-- <div class="work-title-link">近期沟通>></div> -->
        </div>
        <div class="work-table">
          <div class="table-head">
            <div class="col w-100">业务代表</div>
            <div class="col w-200">项目名称</div>
            <div class="col w-200">客户名称</div>
            <div class="col w-100">计划时间</div>
            <div class="col w-full text-left">计划内容</div>
            <!-- <div class="col w-100">到达时间</div>
            <div class="col w-200">达到位置</div> -->
            <div class="col w-100">完成时间</div>
            <div class="col w-200">完成内容</div>
            <div class="col w-scroll" v-if="goalModel.rankVisit && goalModel.rankVisit.items && goalModel.rankVisit.items.length>8"></div>
          </div>
          <div class="table-body" v-if="goalModel.rankVisit && goalModel.rankVisit.items">
            <div class="table-row" v-for="(item, index) in goalModel.rankVisit.items" :key="index" @click="showDetail(item.id)">
              <div class="col w-100" :title="item.user_name">{{item.user_name}}</div>
              <div class="col w-200" :title="item.project_name">{{item.project_name}}</div>
              <div class="col w-200" :title="item.customer_name">{{item.customer_name}}</div>
              <div class="col w-100" :title="formatDate(item.time)">{{formatDate(item.time)}}</div>
              <div class="col w-full text-left" :title="item.cause">{{item.cause}}</div>
              <!-- <div class="col w-100" :title="formatDate(item.fact_time)">{{formatDate(item.fact_time)}}</div>
              <div class="col w-200" :title="item.fact_address">{{item.fact_address}}</div> -->
              <div class="col w-100" :title="formatDate(item.finish_time)">{{formatDate(item.finish_time)}}</div>
              <div class="col w-200" :title="item.finish_memo">{{item.finish_memo}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="board-body-work">
        <div class="work-title">
          <div class="work-title-text">工作总结/会议记录</div>
          <!-- <div class="work-title-link">近期沟通>></div> -->
        </div>
        <div class="work-table">
          <div class="table-head">
            <div class="col w-100">业务代表</div>
            <div class="col w-100">总结时间</div>
            <div class="col w-full text-left">总结内容</div>
            <div class="col w-scroll" v-if="goalModel.rankVisit && goalModel.rankVisit.reviews && goalModel.rankVisit.reviews.length>8"></div>
          </div>
          <div class="table-body" v-if="goalModel.rankVisit && goalModel.rankVisit.reviews">
            <div class="table-row" v-for="(item, index) in goalModel.rankVisit.reviews" :key="index" @click="showReview(item.id)">
              <div class="col w-100" :title="item.user_name">{{item.user_name}}</div>
              <div class="col w-100" :title="formatDate(item.time)">{{formatDate(item.time)}}</div>
              <div class="col w-full text-left" :title="item.memo">{{item.memo}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Drawer :title="teamUser.title.replace('├ ','').trim()+' - '+cycle+' - '+'年度目标'" v-model="goalShow" width="360" :mask-closable="true" :styles="drawerStyles">
      <Form>
        <FormItem label="目标（万）" label-position="top">
          <Input v-model="userGoal.goal" />
        </FormItem>
        <FormItem label="描述" label-position="top">
          <Input type="textarea" :rows="10" v-model="userGoal.memo" />
        </FormItem>
      </Form>
      <div class="drawer-footer">
        <Button style="margin-right: 12px" @click="goalShow = false">取消</Button>
        <Button type="primary" @click="saveUserGoal">保存</Button>
      </div>
    </Drawer>
    <Drawer :title="detailItem.item.user_name+' - 计划详情'" v-if="detailItem && detailItem.item" v-model="detailShow" width="520" :mask-closable="true" :label-width="80" :styles="drawerStyles">
      <div class="drawer-item">
        <div class="drawer-item-row" v-if="detailItem && detailItem.item">
          <div class="drawer-item-label">计划日期</div>
          <div class="drawer-item-value">{{formatDate(detailItem.item.time)}}</div>
        </div>
        <div class="drawer-item-row" v-if="detailItem && detailItem.item">
          <div class="drawer-item-label">相关项目</div>
          <div class="drawer-item-value">{{detailItem.item.project_name}}</div>
        </div>
        <div class="drawer-item-row" v-if="detailItem && detailItem.item && detailItem.item.category==1">
          <div class="drawer-item-label">计划内容</div>
          <div class="drawer-item-value" v-html="getHtml(detailItem.item.cause)"></div>
        </div>
        <div class="drawer-item-row" v-if="detailItem && detailItem.item && detailItem.item.category==1">
          <div class="drawer-item-label">计划状态</div>
          <div class="drawer-item-value">{{getDictText(itemState,detailItem.item.state)}}</div>
        </div>
        <div class="drawer-item-row" v-if="detailItem && detailItem.item && detailItem.item.category==0">
          <div class="drawer-item-label">拜访客户</div>
          <div class="drawer-item-value">{{detailItem.item.customer_name}}</div>
        </div>
        <div class="drawer-item-row" v-if="detailItem && detailItem.item && detailItem.item.category==0">
          <div class="drawer-item-label">拜访事由</div>
          <div class="drawer-item-value" v-html="getHtml(detailItem.item.cause)"></div>
        </div>
        <div class="drawer-item-row" v-if="detailItem && detailItem.item && detailItem.item.category==0">
          <div class="drawer-item-label">拜访状态</div>
          <div class="drawer-item-value">{{getDictText(itemState,detailItem.item.state)}}</div>
        </div>
        <div class="drawer-item-row" v-if="detailItem && detailItem.item && detailItem.item.category==0 && (detailItem.item.state==1 || detailItem.item.state==2)">
          <div class="drawer-item-label">到达时间</div>
          <div class="drawer-item-value">{{formatDate(detailItem.item.fact_time,'yyyy-MM-dd hh:mm')}}</div>
        </div>
        <div class="drawer-item-row" v-if="detailItem && detailItem.item && detailItem.item.category==0 && (detailItem.item.state==1 || detailItem.item.state==2)">
          <div class="drawer-item-label">到达位置</div>
          <div class="drawer-item-value">{{detailItem.item.fact_address}}</div>
        </div>
        <div class="drawer-item-row" v-if="detailItem && detailItem.item && detailItem.item.state==3">
          <div class="drawer-item-label">取消时间</div>
          <div class="drawer-item-value">{{formatDate(detailItem.item.finish_time,'yyyy-MM-dd hh:mm')}}</div>
        </div>
        <div class="drawer-item-row" v-if="detailItem && detailItem.item && detailItem.item.state==3">
          <div class="drawer-item-label">取消原因</div>
          <div class="drawer-item-value" v-html="getHtml(detailItem.item.finish_memo)"></div>
        </div>
        <div class="drawer-item-row" v-if="detailItem && detailItem.item && detailItem.item.state==2">
          <div class="drawer-item-label">完成时间</div>
          <div class="drawer-item-value">{{formatDate(detailItem.item.finish_time,'yyyy-MM-dd hh:mm')}}</div>
        </div>
        <div class="drawer-item-row" v-if="detailItem && detailItem.item && detailItem.item.state==2">
          <div class="drawer-item-label">完成备注</div>
          <div class="drawer-item-value" v-html="getHtml(detailItem.item.finish_memo)"></div>
        </div>
      </div>
      <List v-if="detailItem && detailItem.reply">
        <ListItem v-for="(re, index) in detailItem.reply" :key="index">
          <ListItemMeta :title="re.user_name+ ' '+formatDate(re.time,'MM-dd hh:mm')+(re.isread=='1'?'（已读）':'')" :description="re.content" />
        </ListItem>
      </List>
      <div class="drawer-footer" v-if="detailItem">
        <Input type="textarea" v-model="detailItem.content" :rows="6" placeholder="请输入评论..." style="width: 100%;margin:5px 0 15px;" />
        <Button style="margin-right: 12px" @click="detailShow = false">关闭</Button>
        <Button type="primary" @click="saveItemReply">提交评论</Button>
      </div>
    </Drawer>
    <Drawer :title="detailReview.item.user_name+' - 总结详情'" v-if="detailReview && detailReview.item" v-model="reviewShow" width="520" :mask-closable="true" :label-width="80" :styles="drawerStyles">
      <div class="drawer-item">
        <div class="drawer-item-row" v-if="detailReview && detailReview.item">
          <div class="drawer-item-label">总结时间</div>
          <div class="drawer-item-value">{{formatDate(detailReview.item.time)}}</div>
        </div>
        <div class="drawer-item-row" v-if="detailReview && detailReview.item">
          <div class="drawer-item-label">总结内容</div>
          <div class="drawer-item-value" v-html="getHtml(detailReview.item.memo)"></div>
        </div>
      </div>
      <List v-if="detailReview && detailReview.reply">
        <ListItem v-for="(re, index) in detailReview.reply" :key="index">
          <ListItemMeta :title="re.user_name+ ' '+formatDate(re.time,'MM-dd hh:mm')+(re.isread=='1'?'（已读）':'')" :description="re.content" />
        </ListItem>
      </List>
      <div class="drawer-footer" v-if="detailReview">
        <Input type="textarea" v-model="detailReview.content" :rows="6" placeholder="请输入评论..." style="width: 100%;margin:5px 0 15px;" />
        <Button style="margin-right: 12px" @click="reviewShow = false">关闭</Button>
        <Button type="primary" @click="saveItemReview">提交评论</Button>
      </div>
    </Drawer>
  </div>
</template>
<script>
import { formatDate } from "@/utils/format.js";
import { getDictText, visitPlanState } from "@/utils/dict.js";
import { GetTeamGoalDetail, GetTeamUser, SaveTeamGoal, GetVisitPlanItem, GetVisitPlanReview, ReplyDaily } from "@/api";
import { loadDetailAction, loadDataAction, submitAction } from "@/utils/action.js";

export default {
  data: function () {
    return {
      goalShow: false,
      detailShow: false,
      reviewShow: false,
      rankType: "区域",
      cycle: 2022,
      cycles: [{ year: 2021 }, { year: 2022 }],
      teamId: '0',
      teamUser: { title: '' },
      teamUsers: [],
      goalModel: { total: {} },
      userGoal: {},
      detailItem: { item: {}, reply: [], content: "" },
      detailReview: { item: {}, reply: [], content: "" },
      itemState: visitPlanState,
      drawerStyles: {
        height: 'calc(100% - 260px)',
        overflow: 'auto',
        position: 'static',
      },
      chartProduct: {},
      chartVisit: {},
      productOption: {
        legend: {
          top: 'bottom'
        },
        tooltip: {
          trigger: 'item'
        },
        color: ["#5470c6", "#91cc75", "#fac858", "#ee6666", "#73c0de", "#3ba272", "#fc8452", "#9a60b4", "#ea7ccc"],
        series: [
          {
            type: 'pie',
            radius: ["35%", "80%"],
            center: ["50%", "40%"],
            data: []
          }
        ]
      },
      visitOption: {
        grid: {
          top: '10px',
          left: '10px',
          right: '20px',
          bottom: '10px',
          containLabel: true
        },
        tooltip: {
          trigger: 'item'
        },
        color: ["#559AF4"],
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          data: []
        },
        series: [{
          type: 'bar',
          data: []
        }]
      },
    }
  },
  methods: {
    formatDate: formatDate,
    getDictText: getDictText,
    initPage: function () {
      loadDataAction(this, GetTeamUser, {}, (data) => {
        this.teamUsers = data;
        if (this.teamUsers.length > 0) {
          this.teamUser = this.teamUsers[0];
          this.teamId = this.teamUser.id;
        }
        this.dataRequest();
      });
    },
    getHtml: function (text) {
      return text ? text.replace(/\n/g, '<br/>') : "";
    },
    getUserGoal: function () {
      this.goalShow = true;
    },
    showDetail: function (id) {
      loadDetailAction(this, GetVisitPlanItem, { id: id }, (data) => {
        this.detailItem = data;
        this.detailShow = true;
      });
    },
    showReview: function (id) {
      loadDetailAction(this, GetVisitPlanReview, { id: id }, (data) => {
        this.detailReview = data;
        this.reviewShow = true;
      });
    },
    saveUserGoal: function () {
      this.goalShow = false;
      var model = { user_id: this.teamUser.id, user_name: this.teamUser.title.replace('├ ', '').trim(), cycle: this.cycle, type: 1, goal: this.userGoal.goal, memo: this.userGoal.memo };
      submitAction(this, SaveTeamGoal, { model: model }, () => { this.dataRequest(); });
    },
    saveItemReply: function () {
      this.detailShow = false;
      var model = { type: 1, daily_id: this.detailItem.item.id, content: this.detailItem.content, order_name: "工作计划" };
      submitAction(this, ReplyDaily, { model: model });
    },
    saveItemReview: function () {
      this.reviewShow = false;
      var model = { type: 1, daily_id: this.detailReview.item.id, content: this.detailReview.content, order_name: "工作总结" };
      submitAction(this, ReplyDaily, { model: model });
    },
    dataRequest: function () {
      var selectTU = this.teamUsers.filter(tu => tu.id === this.teamId);
      this.teamUser = (selectTU.length === 1) ? selectTU[0] : { title: '' };

      loadDetailAction(this, GetTeamGoalDetail, { cycle: this.cycle, teamId: this.teamUser.id, teamType: this.teamUser.type }, (data) => {
        this.goalModel = data;
        this.userGoal = data && data.total ? data.total.userGoal : {};

        this.productOption.series[0].data = this.goalModel.rankProduct;
        this.chartProduct.setOption(this.productOption);

        this.visitOption.yAxis.data = this.goalModel.rankVisit.names;
        this.visitOption.series[0].data = this.goalModel.rankVisit.values;
        this.chartVisit.setOption(this.visitOption);
      });
    },
  },
  mounted() {
    this.chartProduct = this.$echarts.init(document.getElementById("chart_product"));
    this.chartVisit = this.$echarts.init(document.getElementById("chart_visit"));
    this.initPage();
  }
}
</script>
<style scoped>
.board {
  display: flex;
  background: #f4f5fb;
  flex-direction: column;
}
/* 顶部鼓励文字 和 查询栏 */
.board-head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 60px;
  padding: 0 12px;
  background: #fff;
  color: #848484;
  align-items: center;
}
.board-head-title {
  font-size: 16px;
  height: 30px;
  line-height: 32px;
  padding-left: 36px;
  background-image: url("../../../static/board/icon-comeon.png");
  background-repeat: no-repeat;
  background-size: 30px 30px;
}
.board-head-query {
  text-align: left;
}
.board-body {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 12px;
}
/* 头部汇总数据 */
.board-body-total {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: -12px;
}
.board-body-total .total-box {
  margin-left: 12px;
  height: 128px;
  background: #fff;
  width: 20%;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #dee8fb;
}
.total-box-title {
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.total-box-title .title-text {
  font-size: 16px;
}
.total-box-title .title-link {
  width: 18px;
  height: 18px;
  cursor: pointer;
  background-image: url("../../../static/board/icon-edit.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.total-box .total-box-number {
  flex: 1;
  font-size: 32px;
  text-align: left;
  display: flex;
  align-items: center;
  margin-top: 5px;
}
.total-box-image {
  width: 100%;
  height: 40px;
  background-image: url("../../../static/board/person-chance.png");
  background-repeat: no-repeat;
  background-size: 100% 33px;
  background-position-y: bottom;
}
.total-box-image.img-visit {
  background-image: url("../../../static/board/person-visit.png");
}
.total-box-process {
  height: 40px;
  display: flex;
  flex-direction: column;
}
.total-box-process .process-text {
  height: 28px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.total-box-process .process-text .process-text-rate,
.total-box-process .process-text .process-text-value {
  font-size: 12px;
  display: flex;
  align-items: center;
}
.total-box-process .process-chart {
  background: #ffdada;
  border-radius: 5px;
  height: 10px;
  width: 100%;
}
.total-box-process .process-chart .process-chart-value {
  background: #ff6b6b;
  border-radius: 5px;
  height: 10px;
}
/* 排名和项目列表 */
.board-body-rank {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: -12px;
  margin-top: 12px;
}
.board-body-rank .rank-box,
.board-body-rank .table-box {
  margin-left: 12px;
  height: 320px;
  background: #fff;
  width: calc(20% - 6px);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  border: 1px solid #dee8fb;
}
.board-body-rank .table-box.w60 {
  width: calc(60% + 6px);
}
.board-body-rank .rank-box-title,
.board-body-rank .table-box-title,
.board-body-work .work-title {
  font-size: 16px;
  margin: 10px;
  height: 18px;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.rank-box-table {
  display: flex;
  flex-direction: column;
}
.table-body {
  display: flex;
  flex-direction: column;
  height: 240px;
  margin: 0 10px;
  overflow-y: auto;
}
.table-head {
  background: #e1edfc;
  margin: 0 10px;
}
.table-head,
.table-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;
  min-height: 30px;
  align-items: center;
  padding: 0 5px;
}
.col {
  font-size: 14px;
  height: 14px;
  overflow: hidden;
}
.col.w-40 {
  width: 40px;
}
.col.w-100 {
  width: 100px;
}
.col.w-200 {
  width: 200px;
}
.col.w-scroll {
  width: 18px;
}
.col.w-full {
  flex: 1;
}
.col.text-right {
  text-align: right;
}
.col.icon-rank1,
.col.icon-rank2,
.col.icon-rank3 {
  height: 24px;
  background-size: 23px 24px;
  background-repeat: no-repeat;
  background-position: center center;
}
.col.icon-rank1 {
  background-image: url("../../../static/board/icon-person-rank1.png");
}
.col.icon-rank2 {
  background-image: url("../../../static/board/icon-person-rank2.png");
}
.col.icon-rank3 {
  background-image: url("../../../static/board/icon-person-rank3.png");
}
.col.icon-rank span {
  background: #0577fe;
  color: #fff;
  padding: 0 5px;
  display: inline-block;
  border-radius: 10px;
}
.chart-body {
  height: 270px;
}
/* 工作计划 */
.board-body-work {
  display: flex;
  height: 320px;
  margin-top: 12px;
  background: #fff;
  border-radius: 5px;
  flex-direction: column;
  border: 1px solid #dee8fb;
}
.work-title-link {
  font-size: 14px;
  cursor: pointer;
}
.work-table {
  flex: 1;
}
.work-table-head {
  background: #fff;
}
.work-table-row {
  background: #fff;
}
/* 插件 */
.drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
.drawer-item {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: 10px;
  border-bottom: 1px solid #e8eaec;
}
.drawer-item-row {
  display: flex;
  width: 100%;
  margin: 6px 0;
  line-height: 22px;
  flex-direction: row;
}
.drawer-item-label {
  width: 80px;
  color: #999;
}
.drawer-item-value {
  width: 400px;
}
</style>